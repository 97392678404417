import { Fragment, ReactElement } from "react";
import { graphql } from "gatsby";
import Helmet from "../../../components/Helmet";
import SupportDownloadCard from "../../../components/Widgets/SupportDownloadCard";
import * as styles from "./DownloadsPage.module.scss";
import CtaLink from "../../../components/Shared/CtaLink";
import { heroImage } from "../../../components/Icons";

interface IDownloadsPageProps {
    data: GatsbyTypes.DownloadsPageQuery;
}

export default function DownloadsPage({ data }: IDownloadsPageProps): ReactElement {
    return (
        <>
            <Helmet title={data.strapi.downloadPage?.title?.base?.heading} description={""} />
            <div className="headerMainContainer">
                <div className="mainBox">
                    <h1>{data.strapi.downloadPage?.title?.base?.heading}</h1>
                </div>
                <div className="headerImage">{heroImage}</div>
            </div>
            <div className={`mainBox ${styles.cards}`}>
                {data.strapi.downloads
                    ?.filter(x => !!x)
                    .map(
                        (x, i) =>
                            !!x && (
                                <Fragment key={i}>
                                    <SupportDownloadCard
                                        key={x.id}
                                        downloadGridText={data.strapi.downloadGridText as GatsbyTypes.strapi_DownloadGridText}
                                        product={x.product as GatsbyTypes.strapi_Product}
                                        download={x as GatsbyTypes.strapi_Download}
                                        className={styles.singleCard}
                                    />
                                </Fragment>
                            )
                    )}
                <div className={styles.supportCard}>
                    <div className={styles.textContainer}>
                        <h4>{data.strapi.downloadPage?.base?.heading}</h4>
                        <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: data.strapi.downloadPage?.base?.paragraph ?? "" }} key={data.strapi.downloadPage?.base?.id}></div>
                        {data.strapi.downloadPage?.cta && <CtaLink cta={data.strapi.downloadPage?.cta as GatsbyTypes.strapi_ComponentSharedCtaContent} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export const query = graphql`
    query DownloadsPage {
        strapi {
            downloads {
                id
                name
                product {
                    id
                    name
                    description
                    color
                }
                installer {
                    id
                }
                manual {
                    id
                }
                otherFiles {
                    id
                }
                perpetual {
                    id
                }
                activation {
                    id
                }
                generic {
                    base {
                        heading
                    }
                    id
                }
            }
            downloadPage {
                title {
                    base {
                        heading
                    }
                }
                base {
                    heading
                    paragraph
                    id
                }
                cta {
                    link
                    text
                }
            }
            downloadGridText {
                installer {
                    mainTitle
                    newTitle
                }
                manual {
                    mainTitle
                    newTitle
                }
                otherFiles {
                    mainTitle
                }
                perpetual {
                    mainTitle
                }
                activation {
                    mainTitle
                }
            }
        }
    }
`;
