import React, { CSSProperties, DetailedHTMLProps, Fragment, HTMLAttributes, ReactElement, useRef } from "react";
import CtaLink from "../../Shared/CtaLink";
import * as styles from "./SupportDownloadCard.module.scss";
import { className } from "../../../shared/ClassName";

interface SupportDownloadCardProps {
    product: GatsbyTypes.strapi_Product;
    downloadGridText: GatsbyTypes.strapi_DownloadGridText;
    download: GatsbyTypes.strapi_Download;
    className?: string;
}

const properties = Object.freeze(["installer", "manual", "otherFiles", "perpetual", "activation"]);

function createAutomaticLink(productDownload: GatsbyTypes.strapi_Download, downloadGrid: GatsbyTypes.strapi_DownloadGridText, propertyName: string): ReactElement {
    const id = productDownload?.id;
    const downloadItems = (productDownload as any)[propertyName]?.length ?? 0;
    const text = (downloadGrid as any)[propertyName].mainTitle;
    const link = `support/downloads/${productDownload?.id}#${propertyName}`;

    if (downloadItems === 0) return <Fragment key={`${text}${id}`}></Fragment>;

    const linkText = downloadItems === 1 && (propertyName === "installer" || propertyName === "manual") ? (downloadGrid as any)[propertyName].newTitle : text;

    return createCtaLink(id, linkText, link);
}

function createCtaLink(id: string, text: string, link: string): ReactElement {
    return (
        <CtaLink key={`${text}${id}`} cta={{ id, text, link } as GatsbyTypes.strapi_ComponentSharedCtaContent}>
            <h6 className={styles.productLink}>{text}</h6>
        </CtaLink>
    );
}

export default function SupportDownloadCard({ product, downloadGridText, download, className: cssName }: SupportDownloadCardProps): React.ReactElement {
    return download ? (
        <div {...className(styles.cardContainer, cssName)} style={{ "--product-color": product?.color } as CSSProperties}>
            <CtaLink cta={{ link: `support/downloads/${download?.id}` } as GatsbyTypes.strapi_ComponentSharedCtaContent}>
                <h3>{product?.name ? product.name : download.name}</h3>
            </CtaLink>
            <hr className={styles.lineDivisor} />
            {properties.map(x => createAutomaticLink(download, downloadGridText, x))}
            {downloadGridText.support?.id && createCtaLink(`support/downloads/${download.id}#softwaresupport`, `${downloadGridText.support.base?.heading}`, download.id)}

            {download.generic && download.generic?.length > 0 && (
                <>{download.generic.map(y => createCtaLink(`support/downloads/${download?.id}#generic${y?.id}`, `${y?.base?.heading}`, `support/downloads/${download?.id}#generic${y?.id}`))}</>
            )}
        </div>
    ) : (
        <></>
    );
}
